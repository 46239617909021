import _ from 'lodash';
import * as PIXI from 'pixi.js';

import { ELoaderStages, ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';
import { formatNumber as utilsFormatNumber } from '@phoenix7dev/utils-fe';

import variables from '../assets/styles/export.module.scss';
import { FAILURE_RETRIES } from '../config';
import { EventTypes, UserBonus } from '../global.d';
import { setBetAmount, setCoinAmount, setCoinValue, setCurrency, setSlotConfig, setStressful } from '../gql/cache';
import i18n from '../i18next';
import { MAXIMUM_FRACTION_DIGITS, MINIMUM_FRACTION_DIGITS, eventManager } from '../slotMachine/config';
import { Features } from '../slotMachine/d';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
  });
};
export const loadPixiAssets = (assets: PIXI.IAddOptions[]): Promise<void> => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    PIXI.Loader.shared.add(assets);
    let tries = FAILURE_RETRIES;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.error(err);
      }
    }

    return success ? resolve() : reject();
  });
};

/* export const loadImages = async (
  assets: IterableIterator<[string, IResource]>,
  cb?: CallableFunction,
): Promise<void> => {
  let promises: Promise<IResource>[] = [];
  for (const [key, value] of assets) {
    promises.push(
      new Promise((resolve, reject) => {
        const asset: HTMLImageElement = new Image();
        asset.src = value.source;
        asset.onload = () => {
          if (cb) cb(value.key);
          resolve(value);
        };
        asset.onerror = () => reject(value);
      }),
    );
  }

  let tries = FAILURE_RETRIES;
  let success = false;

  while (tries > 0) {
    try {
      tries -= 1;
      const result: Array<PromiseRejectedResult | PromiseFulfilledResult<IResource>> = await Promise.allSettled(
        promises,
      );
      const failed = _.filter(result, (asset) => asset.status === 'rejected') as Array<PromiseRejectedResult>;

      if (failed.length) {
        promises = failed.map((rejected) => {
          return new Promise((resolve, reject) => {
            const asset: HTMLImageElement = new Image();
            asset.src = rejected.reason.source;
            asset.onload = () => {
              if (cb) cb(rejected.reason.key);
              resolve(rejected.reason);
            };
            asset.onerror = () => reject(rejected.reason);
          });
        });
        continue;
      }
      success = true;
      break;
    } catch (err) {
      console.error(err);
    }
  }

  return success ? Promise.resolve() : Promise.reject();
};
 */

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  switch (stage?.name) {
    case ELoaderStages.AUTH:
      if (setStressful().show && setStressful().message === i18n.t(['errors.UNKNOWN.UNKNOWN'])) {
        break;
      }
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t(['errors.CLIENT.INVALID_CLIENT_TOKEN', 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
      break;
    default:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
  }
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isMobilePortrait = (width: number, height: number): boolean => {
  const isPortrait = height >= width;
  const maxWidth = parseInt(variables.breakpointMobilePortraitMax, 10);

  return isPortrait && width <= maxWidth;
};

export const isBuyFeatureEnabled = (features: Features[] = []): boolean => {
  const freeSpinFeature = features.find((i) => i.id === 'freeSpins');

  return freeSpinFeature?.enabled || false;
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (isMobilePortrait(width, height)) {
    return height * (parseInt(variables.bottomHeightPercentMobilePortrait, 10) / 100);
  }
  return height * (parseInt(variables.bottomHeightPercent, 10) / 100);
};

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};

export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};
export const formatNumber = (currency = 'FUN', value = 0, showCurrency = false): string => {
  return utilsFormatNumber({
    currency,
    value,
    showCurrency,
    minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
    maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
  });
};

export const nextTick = (callback: () => void): number => window.setTimeout(callback, 0);

export const updateTextScale = (text: PIXI.Text, maxWidth: number, maxHeight: number): void => {
  text.scale.set(1, 1);
  text.updateText(true);
  const ratio = Math.min(1, Math.min(maxWidth / text.width, maxHeight / text.height));
  text.scale.set(ratio, ratio);
};

export * from './fallback';
export * from './helper';
// Avoiding certified files.
// export * from './utils'

export const queryParams = new URLSearchParams(window.location.search);

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i];

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0;
};

export const updateCoinValueAfterBonuses = (): void => {
  // updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
  const coinValue = setSlotConfig().clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), setSlotConfig().clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

export const calcActiveUserBonusTotalWinAmount = (freeRoundBonus: UserBonus) => {
  return freeRoundBonus.totalWinAmount ? freeRoundBonus.totalWinAmount / freeRoundBonus.coinValue : 0;
};
