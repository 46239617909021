import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import * as PIXI from 'pixi.js';
import React, { useEffect, useState } from 'react';

import AudioHowl from '@phoenix7dev/play-music';
import { Loader, ProgressBar } from '@phoenix7dev/shared-components';
import { ELoaderStages } from '@phoenix7dev/shared-components/dist/loader/d';
import { rebuildStorageCache } from '@phoenix7dev/utils-fe';

import {
  LOADER_SPRITE_TEXTURES,
  LOADER_TEXTURES,
  PRELOAD_SPINE_TEXTURES,
  SPINE_ATLASES,
  SPINE_LOADER_TEXTURES,
  audioSprite,
  audioSpriteVolume,
} from '../../config';
import {
  BonusStatus,
  EventTypes,
  GameMode,
  IAuthInput,
  IBonus,
  IUserBalance,
  ReplayFreeSpinBets,
  UserBonus,
  freeRoundBonusId,
  reelSets,
} from '../../global.d';
import {
  setBetAmount,
  setBonuses,
  setBrokenBuyFeatureGame,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setCurrentFreeSpinsTotalWin,
  setFreeRoundBonus,
  setFreeRoundsTotalWin,
  setGameMode,
  setIsAuthorized,
  setIsBuyFeaturePopupOpened,
  setIsEnabledSpaceSpin,
  setIsLeftHandMode,
  setIsMiniPayTable,
  setIsMobile,
  setIsSoundOn,
  setIsSuspended,
  setIsTurboSpin,
  setProgress,
  setReelSetId,
  setReplayBet,
  setReplayFreeSpinBets,
  setReplayFreeSpinReelSetId,
  setSkipIntroScreen,
  setSlotConfig,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { IConfig, ISlotHistoryData } from '../../gql/d';
import { authGql } from '../../gql/mutation';
import {
  ReelSetType,
  betsByInitialRoundId,
  configGql,
  getBonuses,
  getProgressGql,
  getSlotGql,
  getUserBonuses,
  getUserGql,
  slotHistoryGql,
} from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { eventManager } from '../../slotMachine/config';
import { ISlotData } from '../../slotMachine/d';
import {
  calcActiveUserBonusTotalWinAmount,
  findSubstituteCoinAmount,
  getGameModeByReelSetId,
  isBaseGameMode,
  isFreeSpinsMode,
  isMobileDevice,
  loadErrorHandler,
  loadPixiAssets,
  parseQuery,
  queryParams,
} from '../../utils';
import { remoteStorage } from '../../utils/remoteStorage';
import Resources from '../../utils/resources';

import styles from './loadScreen.module.scss';

const LoadScreen: React.FC = () => {
  const { data } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);

  const { data: config } = useQuery<IConfig>(configGql);
  const { isSoundOn } = config!;
  const [isShowContent, setShowContent] = useState(true);
  const { progress } = data!;

  const [getAuth] = useMutation<
    { auth: { sessionId: string } },
    { input: Omit<IAuthInput, 'slotId' | 'lng' | 'home'> }
  >(authGql, {
    onCompleted({ auth: { sessionId } }) {
      const { slotId } = parseQuery<IAuthInput>();
      setSlotConfig({
        ...setSlotConfig(),
        id: slotId,
        sessionId,
      });
      setIsAuthorized(!!data);
    },
  });

  useEffect(() => {
    const getReplayBetFreeSpins = async () => {
      const replayBetFreeSpins = await client.query<
        {
          betsByInitialRoundId: ReplayFreeSpinBets[];
        },
        { initialRoundId: string }
      >({
        query: betsByInitialRoundId,
        variables: {
          initialRoundId: setReplayBet(),
        },
      });
      if (replayBetFreeSpins.data.betsByInitialRoundId.length) {
        const replayFreeSpins = replayBetFreeSpins.data.betsByInitialRoundId.map((e) => e.id);
        setReplayFreeSpinBets(replayFreeSpins);
        setReplayFreeSpinReelSetId(replayBetFreeSpins.data.betsByInitialRoundId[0].reelSetId);
      }
    };
    const getUserBalance = async () => {
      const userBalance = await client.query<{ user: IUserBalance }>({
        query: getUserGql,
        fetchPolicy: 'network-only',
      });
      setCurrency(userBalance.data.user.balance.currency);
    };
    const getLastBet = async () => {
      const betsData = await client.query<{ bets: ISlotHistoryData }>({
        query: slotHistoryGql,
        variables: {
          input: { last: 2, filter: { slotId: setSlotConfig().id } },
        },
        fetchPolicy: 'network-only',
      });
      if (betsData.data.bets.edges[1]) {
        if (isFreeSpinsMode(getGameModeByReelSetId(betsData.data.bets.edges[1].node.reelSetId))) {
          const winCoinAmount =
            betsData.data.bets.edges[0].node.result.winCoinAmount +
            betsData.data.bets.edges[1].node.result.winCoinAmount;
          setUserLastBetResult({
            ...betsData.data.bets.edges[0].node,
            result: {
              ...betsData.data.bets.edges[0].node.result,
              winCoinAmount,
            },
          });
        } else {
          setUserLastBetResult(betsData.data.bets.edges[1].node);
        }
      } else if (betsData.data.bets.edges[0]) {
        if (isBaseGameMode(getGameModeByReelSetId(betsData.data.bets.edges[0].node.reelSetId))) {
          setUserLastBetResult(betsData.data.bets.edges[0].node);
        }
      }
    };
    const getPurchasableBonuses = async () => {
      const bonusData = await client.query<{ bonuses: IBonus[] }>({
        query: getBonuses,
        variables: { input: { purchasable: true, slotId: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      setBonuses(bonusData.data.bonuses);
    };
    const checkBonusGame = async () => {
      const activeUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });

      // TODO(FRB) on loading
      // ->its locally for testing.
      /* const originActiveUserBonusData = await client.query<{
        userBonuses: UserBonus[];
      }>({
        query: getUserBonuses,
        variables: {
          input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
        },
        fetchPolicy: 'network-only',
      });
      const activeUserBonusData: ApolloQueryResult<{
        userBonuses: UserBonus[];
      }> = JSON.parse(JSON.stringify(originActiveUserBonusData));

      activeUserBonusData.data.userBonuses.push({
        ...(setCurrentBonus() as UserBonus),
        id: freeRoundBonusId,
        bonusId: freeRoundBonusId,
        coinAmount: 1,
        //coinValue: 1000, // KRW
        coinValue: 1, // EUR
        rounds: 2,
        totalWinAmount: 0,
        isActive: true,
        currentRound: 0,
      }); */
      // <-its locally for testing.

      const freeSpinsBonus = activeUserBonusData.data.userBonuses.find((ub) => ub.bonus.type === 'FREE_SPIN');
      if (freeSpinsBonus) {
        const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeSpinsBonus);
        setBrokenGame(true);
        setCurrentBonus({
          ...freeSpinsBonus,
          isActive: true,
          currentRound: 0,
          totalRounds: 0,
        });
        setCurrentFreeSpinsTotalWin(totalWinAmount);
      }

      const buyFeatureBonus = activeUserBonusData.data.userBonuses.find((ub) => ub.bonus.type === 'SPECIAL_ROUND');
      if (buyFeatureBonus) {
        setBrokenBuyFeatureGame(buyFeatureBonus.id);
        setIsBuyFeaturePopupOpened(true);
        setCurrentBonus({
          ...buyFeatureBonus,
          isActive: true,
          currentRound: 0,
        });
      }

      const freeRoundBonus = activeUserBonusData.data.userBonuses.find((e) => e.bonusId === freeRoundBonusId);
      if (freeRoundBonus) {
        if (!freeSpinsBonus || freeSpinsBonus.data.frbReferenceId) {
          const totalWinAmount = calcActiveUserBonusTotalWinAmount(freeRoundBonus);
          setFreeRoundBonus({
            id: freeRoundBonus.id,
            bonusId: freeRoundBonus.bonusId,
            coinAmount: freeRoundBonus.coinAmount,
            coinValue: freeRoundBonus.coinValue,
            status: freeRoundBonus.status,
            rounds: freeRoundBonus.rounds,
            totalWinAmount: totalWinAmount,

            isActive: true,
            currentRound: 0,
          });
          setFreeRoundsTotalWin(totalWinAmount);
        }
      }
    };

    const getSlotData = async () => {
      const slotData = await client.query<{ slot: ISlotData }>({
        query: getSlotGql,
        variables: { input: { id: setSlotConfig().id } },
        fetchPolicy: 'network-only',
      });
      const { slot } = slotData.data;

      const lines = slot.lines.map((_, index) => index);
      setSlotConfig({
        ...setSlotConfig(),
        clientSettings: slot.clientSettings,
        icons: slot.icons,
        reels: slot.reels,
        winLines: slot.lines,
        lines,
        lineSet: slot.lineSets[0],
        settings: slot.settings,
      });
      // todo add logic to pick gamemode and reelsetid
      setReelSetId(
        slot.reels.find((reelSet) => reelSet.type === ReelSetType.DEFAULT)?.id || reelSets[GameMode.REGULAR],
      );
      setGameMode(GameMode.REGULAR);
      let coinValue;
      let coinAmount;
      if (setBrokenGame() || setBrokenBuyFeatureGame()) {
        const currentBonus = setCurrentBonus();
        coinValue = currentBonus.coinValue;
        coinAmount = currentBonus.coinAmount;
      } else {
        const lastBetCoinAmount = setUserLastBetResult().id ? setUserLastBetResult().coinAmount : 1;
        coinAmount = findSubstituteCoinAmount(lastBetCoinAmount, slot.clientSettings.coinAmounts.default);
        coinValue = slot.clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
      }
      setCoinValue(coinValue);
      setCoinAmount(coinAmount);
      setWinAmount(setUserLastBetResult().result.winCoinAmount);
      setBetAmount(coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
    };

    setShowContent(true);
    new Loader({ asynchronous: false })
      .stage(20, ELoaderStages.AUTH, async (stage) => {
        setIsMobile(isMobileDevice());
        const { token, clientId } = parseQuery<Omit<IAuthInput, 'slotId' | 'lng'>>();
        const { data } = await getAuth({ variables: { input: { token, clientId } } });

        window.remoteStorage = remoteStorage;
        await remoteStorage.init(data?.auth.sessionId as string);
        rebuildStorageCache<IConfig>('config', {
          isLeftHandMode: setIsLeftHandMode,
          isSoundOn: setIsSoundOn,
          isTurboSpin: setIsTurboSpin,
          isMiniPayTable: setIsMiniPayTable,
          isEnabledSpaceSpin: setIsEnabledSpaceSpin,
          isSkipIntroScreen: setSkipIntroScreen,
        });

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(40, ELoaderStages.BONUS_GAME, async (stage) => {
        if (queryParams.has('replayBetId')) {
          await getReplayBetFreeSpins();
        }

        await getUserBalance();
        await getPurchasableBonuses();
        await getLastBet();
        await checkBonusGame();
        await getSlotData();

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(60, ELoaderStages.PIXI_ASSETS, async (stage) => {
        PIXI.Loader.shared.baseUrl = process.env.PUBLIC_URL;
        await loadPixiAssets(SPINE_ATLASES);
        await loadPixiAssets([
          ...SPINE_LOADER_TEXTURES(setIsMobile()),
          ...PRELOAD_SPINE_TEXTURES,
          ...LOADER_SPRITE_TEXTURES,
          ...LOADER_TEXTURES,
        ]);

        setProgress({
          ...setProgress(),
          status: stage,
        });
      })
      .stage(80, ELoaderStages.AUDIO, async (stage) => {
        AudioHowl.initialize(audioSprite, audioSpriteVolume, true, isSoundOn, setIsSuspended).then(() => {
          eventManager.emit(
            EventTypes.SOUND_INITIALIZED,
            AudioHowl.isRestricted && !(!AudioHowl.restrictionChangedOnIntroScreen && !setIsSoundOn()),
          );
        });
      })
      .onError(async (error, resources) => {
        loadErrorHandler(error, resources);
      })
      .onComplete(async () => {
        setProgress({
          ...setProgress(),
          status: 100,
        });
        eventManager.on(EventTypes.POST_RENDER, () => {
          setProgress({
            ...setProgress(),
            wasLoaded: setSkipIntroScreen(),
          });
          setShowContent(false);
        });
      })
      .load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isShowContent) return null;
  return (
    <div className={styles.loadScreenWrapper}>
      <div className={styles.logo}>
        <img
          draggable="false"
          alt="logo"
          src={Resources.getSource(ResourceTypes.logo)}
          className={styles.companyLogo}
        />
      </div>
      <ProgressBar
        className={styles.progressBar}
        type="line"
        trailWidth={2}
        trailColor="#000000"
        strokeWidth={2}
        strokeColor="#fcf7cd"
        percent={progress?.status || 0}
      />
    </div>
  );
};

export default LoadScreen;
